import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

const ganadores = () => {
  return (
    <Layout>
      <main className="contenedor">
        {/* <div className="my-3 bg-white text-center">
          <StaticImage
            src="https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/332132311_1255747675292109_6325358857137656029_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=N7Njo1UlgHgAX8cDdfd&_nc_ht=scontent-nrt1-1.xx&oh=00_AfCr2fieVIoOIX4sNitz82CAurYIUchexigCfn87NU46Cw&oe=63F77C32"
            alt="ganadores"
          />
        </div> */}
      </main>
    </Layout>
  )
}

export default ganadores
